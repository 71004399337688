<template>
	<div>
		<img src="@/assets/XFarmsLogo.png" class="w-50" alt="" />
		<div>
			<h2>An unhandled error occured</h2>
			<h4></h4>
			<router-link
				:to="{ name: 'home' }"
				exact
				>
				<h4>Go back to home page</h4>
        </router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "Error"
}
</script>

<style scoped>
.logo-err{
	width: 50px;
	height: 50px;
}
</style>